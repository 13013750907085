import React from "react";
import { Tabs } from "antd";
import "./News.css";
import { Table } from "antd";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import I18next from "i18next";

export default function MoreNews() {
  const { t } = useTranslation();

  const dataSourceAll = [
    {
      date: "2025, 01",
      content: (
        <a
          href="http://www.bokuennews.com/news/article.html?no=254439"
          target="_blank"
        >
          {t("news-more-t14")}
          {/* 마이허브, 43억원 시리즈 A 투자 유치 성공 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2024, 07",
      content: (
        <a
          href="https://www.linkedin.com/posts/maihub_introducing-maihub-to-malaysias-national-activity-7223973096607641603-VC_u?utm_source=share&utm_medium=member_desktop"
          target="_blank"
        >
          {t("news-more-t13")}
          {/* */}
        </a>
      ),
      category: "Event",
    },
    {
      date: "2024, 06",
      content: (
        <a
          href="https://www.linkedin.com/posts/maihub_maihub-mailink-ai-activity-7209098526629216257-o9AP?utm_source=share&utm_medium=member_desktop"
          target="_blank"
        >
          {t("news-more-t12")}
          {/* 마이허브 중기부 TIPS 선정  */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2024, 06",
      content: (
        <a>
          {t("news-more-t11")}
          {/* 마이허브 왕진연구소와 MOU 체결*/}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2024, 03",
      content: (
        <a
          href="http://www.bokuennews.com/news/article.html?no=241708"
          target="_blank"
        >
          {t("news-more-t10")}
          {/* 'maiLink' 말레이시아 MDA 인증 획득 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2024, 03",
      content: (
        <a
          href="https://kr.aving.net/news/articleView.html?idxno=1788079"
          target="_blank"
        >
          {t("news-more-t9")}
          {/* 마이허브 2024년 3월 개최된 'KIMES 2024'에 참가 */}
        </a>
      ),
      category: "Event",
    },
    {
      date: "2024, 02",
      content: (
        <a
          href="http://www.hitnews.co.kr/news/articleView.html?idxno=52175"
          target="_blank"
        >
          {t("news-more-t8")}
          {/* 마이허브 이지스헬스케어와 MOU 체결 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2023, 11",
      content: (
        <a
          href="http://www.hitnews.co.kr/news/articleView.html?idxno=50342"
          target="_blank"
        >
          {t("news-more-t7")}
          {/* 마이허브 의료 AI 전문 투자 회사인 매쉬업엔젤스로 부터 최기 투자 유치 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2023, 11",
      content: I18next.t("news-more-t6"),
      // "국제 표준화 기구가 제정한 의료기기 분야의 국제 표준 규견인 IOS 13485 인증 획득",

      category: "Notice",
    },
    {
      date: "2023, 09",
      content: I18next.t("news-more-t5"),
      //  "제 42회 대한위대장내시경학회 추계학술대에 참가",

      category: "Event",
    },
    {
      date: "2023, 08",
      content: (
        <a
          href="http://www.bosa.co.kr/news/articleView.html?idxno=2202814"
          target="_blank"
        >
          {t("news-more-t4")}
          {/* 식품의약품안전처로부터 국내 의료기기 제조 및 품질관리기준(GMP)인증 획득 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2023, 03",
      content: (
        <a
          href="https://kr.aving.net/news/articleView.html?idxno=1777547"
          target="_blank"
        >
          {t("news-more-t3")}
          {/* 마이허브 'KIMES 2023'에 참가하여 AI 의료 통합 서비스'마이링크'를 선보임 */}
        </a>
      ),
      category: "Event",
    },
    {
      date: "2022, 10",
      content: (
        <a
          href="https://kr.aving.net/news/articleView.html?idxno=1772922"
          target="_blank"
        >
          {t("news-more-t2")}
          {/* 마이허브 'KIMES Busan 2022'부산의료기기전시회 참가 */}
        </a>
      ),
      category: "Event",
    },
  ];
  const dataSourceEvent = [
    {
      date: "2024, 07",
      content: (
        <a
          href="https://www.linkedin.com/posts/maihub_introducing-maihub-to-malaysias-national-activity-7223973096607641603-VC_u?utm_source=share&utm_medium=member_desktop"
          target="_blank"
        >
          {t("news-more-t13")}
          {/* */}
        </a>
      ),
      category: "Event",
    },
    {
      date: "2024, 03",
      content: (
        <a
          href="https://kr.aving.net/news/articleView.html?idxno=1788079"
          target="_blank"
        >
          {t("news-more-t9")}
          {/* 마이허브 2024년 3월 개최된 'KIMES 2024'에 참가 */}
        </a>
      ),
      category: "Event",
    },

    {
      date: "2023, 09",
      content: I18next.t("news-more-t5"),
      //  "제 42회 대한위대장내시경학회 추계학술대에 참가",

      category: "Event",
    },

    {
      date: "2023, 03",
      content: (
        <a
          href="https://kr.aving.net/news/articleView.html?idxno=1777547"
          target="_blank"
        >
          {t("news-more-t3")}
          {/* 마이허브 'KIMES 2023'에 참가하여 AI 의료 통합 서비스'마이링크'를 선보임 */}
        </a>
      ),
      category: "Event",
    },
    {
      date: "2022, 10",
      content: (
        <a
          href="https://kr.aving.net/news/articleView.html?idxno=1772922"
          target="_blank"
        >
          {t("news-more-t2")}
          {/* 마이허브 'KIMES Busan 2022'부산의료기기전시회 참가 */}
        </a>
      ),
      category: "Event",
    },
  ];

  const dataSourceNotice = [
    {
      date: "2025, 01",
      content: (
        <a
          href="http://www.bokuennews.com/news/article.html?no=254439"
          target="_blank"
        >
          {t("news-more-t14")}
          {/* 마이허브, 43억원 시리즈 A 투자 유치 성공 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2024, 06",
      content: (
        <a>
          {t("news-more-t12")}
          {/* 마이허브 중기부 TIPS 선정 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2024, 06",
      content: (
        <a>
          {t("news-more-t11")}
          {/* 마이허브 왕진연구소와 MOU 체결 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2024, 03",
      content: (
        <a
          href="http://www.bokuennews.com/news/article.html?no=241708"
          target="_blank"
        >
          {t("news-more-t10")}
          {/* 'maiLink' 말레이시아 MDA 인증 획득 */}
        </a>
      ),
      category: "Notice",
    },

    {
      date: "2024, 02",
      content: (
        <a
          href="http://www.hitnews.co.kr/news/articleView.html?idxno=52175"
          target="_blank"
        >
          {t("news-more-t8")}
          {/* 마이허브 이지스헬스케어와 MOU 체결 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2023, 11",
      content: (
        <a
          href="http://www.hitnews.co.kr/news/articleView.html?idxno=50342"
          target="_blank"
        >
          {t("news-more-t7")}
          {/* 마이허브 의료 AI 전문 투자 회사인 매쉬업엔젤스로 부터 최기 투자 유치 */}
        </a>
      ),
      category: "Notice",
    },
    {
      date: "2023, 11",
      content: I18next.t("news-more-t6"),
      // "국제 표준화 기구가 제정한 의료기기 분야의 국제 표준 규견인 IOS 13485 인증 획득",

      category: "Notice",
    },

    {
      date: "2023, 08",
      content: (
        <a
          href="http://www.bosa.co.kr/news/articleView.html?idxno=2202814"
          target="_blank"
        >
          {t("news-more-t4")}
          {/* 식품의약품안전처로부터 국내 의료기기 제조 및 품질관리기준(GMP)인증 획득 */}
        </a>
      ),
      category: "Notice",
    },
  ];

  const columns = [
    {
      // title: "날짜",
      title: I18next.t("news-more-date"),
      dataIndex: "date",
      key: "date",
    },
    {
      // title: "내용",
      title: I18next.t("news-more-content"),
      dataIndex: "content",
      key: "content",
    },
    {
      // title: "카테고리",
      title: I18next.t("news-more-category"),
      dataIndex: "category",
      key: "category",
    },
  ];

  return (
    <div className="News-container-back">
      <div className="News-container">
        <div className="News-content">
          <p className="Default-title" data-aos="fade-up">
            {t("news-more-t1")}
            {/* 더 많은 다양한 소식 */}
          </p>
          <div className="Default-seperator" data-aos="fade-up" />
          <div className="Tab-container" data-aos="fade-up">
            <Tabs
              style={{ width: "100%" }}
              defaultActiveKey="1"
              items={[
                {
                  label: "All",
                  key: "1",
                  children: (
                    <Table
                      dataSource={dataSourceAll}
                      columns={columns}
                      data-aos="fade-up"
                      pagination={{
                        position: ["bottomCenter"],
                        defaultPageSize: 5,
                      }}
                      rowKey={(record) => record.data_index}
                    />
                  ),
                },
                {
                  label: "Event",
                  key: "2",
                  children: (
                    <Table
                      dataSource={dataSourceEvent}
                      columns={columns}
                      data-aos="fade-up"
                      pagination={{
                        position: ["bottomCenter"],
                        defaultPageSize: 5,
                      }}
                      rowKey={(record) => record.data_index}
                    />
                  ),
                },
                {
                  label: "Notice",
                  key: "3",
                  children: (
                    <Table
                      dataSource={dataSourceNotice}
                      columns={columns}
                      data-aos="fade-up"
                      pagination={{
                        position: ["bottomCenter"],
                        defaultPageSize: 5,
                      }}
                      rowKey={(record) => record.data_index}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
